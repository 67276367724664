
import Vue from "vue";
import * as dateFns from "date-fns";
import { userMapActions, userMapMutations } from "@/store/modules/user";
import { authMapGetters, authMapState } from "@/store/modules/auth";
import { settingMapActions } from "@/store/modules/setting";
import {
  useField,
  useFormFieldGroup,
  useFormSection,
  useTextField
} from "@/components/FormBuilder/Helpers";
import { objectDifference } from "@/helpers";
import { pick } from "lodash";

export default Vue.extend({
  name: "UserProfile",
  components: {},
  data(): any {
    return {
      errorMessage: "",
      successMessage: "",
      loading: false,
      showPassword: false,
      disabled: false,
      showScheduling: false,
      requestObjArray: [
        "topHeaderMessage",
        "speed",
        "topHeaderLinkText",
        "topHeaderLinkHref",
        "topHeaderIcon",
        "topHeaderColor",
        "topHeaderDismissible",
        "topHeaderEnabled",
        "showTopHeaderLink",
        "topHeaderIconColor",
        "topHeaderMessageColor",
        "isScheduled",
        "startTime",
        "endTime"
      ],
      editing: {},
      schemas: [
        {
          element: "html",
          id: "sectionOne",
          tagName: "div",
          classList: ["mb-8"],
          children: [
            {
              element: "html",
              id: "headerOne",
              tagName: "h3",
              innerText: "Top Header Settings",
              classList: ["font-bold", "text-lg"]
            }
          ]
        },
        {
          element: "html",
          id: "sectionTwo",
          tagName: "div",
          innerText: "",
          classList: ["mb-4"],
          children: [
            useField(
              {
                key: "topHeaderEnabled",
                type: "checkbox",
                label: "Enable Top Header",
                required: false
              },
              ["mb-6"]
            ),
            useField(
              {
                type: "textarea",
                key: "topHeaderMessage",
                placeholder: "",
                label: "Enter message",
                required: true,
                conditions: {
                  and: [
                    {
                      field: "topHeaderEnabled",
                      operator: "equals",
                      value: true
                    }
                  ]
                }
              },
              ["mb-6"]
            ),
            useField(
              {
                key: "showTopHeaderLink",
                type: "checkbox",
                label: "Add a link",
                required: false,
                conditions: {
                  and: [
                    {
                      field: "topHeaderEnabled",
                      operator: "equals",
                      value: true
                    }
                  ]
                }
              },
              ["mb-6"]
            ),
            ...useFormFieldGroup(
              [
                useTextField({
                  key: "topHeaderLinkText",
                  label: "Enter Link Text",
                  value: "Detail",
                  required: false,
                  conditions: {
                    and: [
                      {
                        operator: "equals",
                        field: "showTopHeaderLink",
                        value: true
                      },
                      {
                        field: "topHeaderEnabled",
                        operator: "equals",
                        value: true
                      }
                    ]
                  }
                }),
                useTextField({
                  key: "topHeaderLinkHref",
                  label: "Enter Link Href",
                  required: false,
                  conditions: {
                    and: [
                      {
                        operator: "equals",
                        field: "showTopHeaderLink",
                        value: true
                      },
                      {
                        field: "topHeaderEnabled",
                        operator: "equals",
                        value: true
                      }
                    ]
                  }
                }),
                useField({
                  type: "select",
                  key: "topHeaderIcon",
                  options: [
                    {
                      label: "Success",
                      value: "success"
                    },
                    {
                      label: "Warning",
                      value: "warn"
                    },
                    {
                      label: "Info",
                      value: "info"
                    },
                    {
                      label: "Error",
                      value: "error"
                    }
                  ],
                  label: "Select Link Icon",
                  required: false,
                  conditions: {
                    and: [
                      {
                        operator: "equals",
                        field: "showTopHeaderLink",
                        value: true
                      },
                      {
                        field: "topHeaderEnabled",
                        operator: "equals",
                        value: true
                      }
                    ]
                  }
                })
              ],
              { layout: "3-col" }
            ),
            ...useFormFieldGroup(
              [
                useField({
                  key: "topHeaderColor",
                  type: "select",
                  options: [
                    {
                      label: "Red",
                      value: "red"
                    },
                    {
                      label: "Yellow",
                      value: "yellow"
                    },
                    {
                      label: "Green",
                      value: "green"
                    },
                    {
                      label: "Blue",
                      value: "blue"
                    }
                  ],
                  conditions: {
                    and: [
                      {
                        field: "topHeaderEnabled",
                        operator: "equals",
                        value: true
                      }
                    ]
                  },
                  label: "Select Header Color",
                  required: false
                }),
                useField({
                  key: "topHeaderDismissible",
                  type: "checkbox",
                  label: "Header Should Be Dismissible",
                  required: false,
                  conditions: {
                    and: [
                      {
                        field: "topHeaderEnabled",
                        operator: "equals",
                        value: true
                      }
                    ]
                  }
                })
              ],
              { layout: "3-col" }
            ),
            ...useFormSection([
              ...useFormFieldGroup(
                [
                  useField(
                    {
                      key: "isScheduled",
                      label: "Schedule Message",
                      placeholder: "Schedule Message",
                      required: true,
                      type: "checkbox",
                      conditions: {
                        and: [
                          {
                            field: "topHeaderEnabled",
                            operator: "equals",
                            value: true
                          }
                        ]
                      }
                    },
                    ["mb-2"]
                  )
                ],
                { layout: "2-col" }
              ),
              ...useFormFieldGroup(
                [
                  useField({
                    key: "startTime",
                    label: "Start Date",
                    placeholder: "Start Date",
                    required: true,
                    type: "date",
                    conditions: {
                      and: [
                        {
                          field: "topHeaderEnabled",
                          operator: "equals",
                          value: true
                        },
                        {
                          field: "isScheduled",
                          operator: "equals",
                          value: true
                        }
                      ]
                    }
                  }),
                  useField({
                    key: "endTime",
                    label: "End Date",
                    placeholder: "End Date",
                    required: true,
                    type: "date",
                    conditions: {
                      and: [
                        {
                          field: "topHeaderEnabled",
                          operator: "equals",
                          value: true
                        },
                        {
                          field: "isScheduled",
                          operator: "equals",
                          value: true
                        }
                      ]
                    }
                  })
                ],
                { layout: "2-col" }
              )
            ])
          ]
        }
      ],
      validationData: {}
    };
  },
  created: function() {
    this.getUserDetails().then(() => {
      this.SET_EDIT_USER(this.getCurrentUserId);
      this.editing = Object.assign({}, this.initData);
      this.showScheduling = this.initData.isScheduled;
      if (this.showScheduling) {
        this.editing.topHeaderMessage = this.editing.scheduledHeaderMessage;
      }
    });
  },
  destroyed() {
    this.SET_EDIT_USER("");
  },
  computed: {
    ...authMapGetters(["getCurrentUserId", "isCurrentUserAdmin"]),
    ...authMapState(["initData"]),
    updatedFields(): any {
      const edited = this.editing;
      const original = this.initData;
      const updated =
        edited && original ? objectDifference(edited, original) : {};
      return pick(updated, this.requestObjArray);
    },
    topActionButtonPrimary(): any {
      return {
        text: "Save",
        key: "save",
        loading: this.loading,
        disabled:
          Object.keys(this.updatedFields).length === 0 ||
          !this.validationData.formIsValid
      };
    }
  },
  methods: {
    ...userMapActions(["getUser", "updateUser"]),
    ...userMapMutations(["SET_EDIT_USER", "SET_USER_EDIT_FIELD"]),
    ...settingMapActions(["updateInfoMessageSettings"]),
    async getUserDetails(): Promise<any> {
      try {
        this.loading = true;
        await this.getUser(this.getCurrentUserId);
      } catch (e) {
        this.errorMessage = e.message;
        this.$bugSnagClient.notify(e);
      } finally {
        this.loading = false;
      }
    },
    async update(): Promise<void> {
      this.loading = true;
      try {
        await this.updateInfoMessageSettings(this.updatedFields);
        this.$router.push("/admin/dashboard");
        this.$appNotifySuccess("Message saved successfully");
      } catch (e) {
        this.errorMessage = "Error Saving Message";
      } finally {
        this.loading = false;
      }
    },
    onToolbarItemClick(action: string): void {
      switch (action) {
        case "save":
          if (this.initData.isScheduled) {
            this.$modal.show("existingScheduleModal");
          } else {
            this.update();
          }
          break;
      }
    },
    formFieldChangedHandler(data: { key: string; value: any }) {
      // this.editing[data.key] = data.value;
      // this.editing = { ...this.editing };
      Vue.set(this.editing, data.key, data.value);
    },
    validateDateIsFuture(date: any) {
      const theDate = new Date(date);
      const currentDate = new Date();
      const minDate = dateFns.subDays(currentDate, 1);
      let maxDate = dateFns.addYears(currentDate, 2);
      maxDate = dateFns.setMonth(maxDate, 0);
      maxDate = dateFns.setDate(maxDate, 1);
      return !(theDate > minDate) || !(theDate < maxDate);
    }
  }
});
