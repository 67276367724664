var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('PageWrapDefault',{attrs:{"errorMessage":_vm.errorMessage,"successMessage":_vm.successMessage,"hideInfoMessage":false,"topActionButtonPrimary":_vm.topActionButtonPrimary},on:{"toolbarSelectItem":_vm.onToolbarItemClick,"errorMessageClosed":function($event){_vm.errorMessage = ''},"successMessageClosed":function($event){_vm.successMessage = ''}}},[_c('div',{attrs:{"id":"settings-page"}},[_c('form-builder',{ref:"formElement",attrs:{"schemas":_vm.schemas,"formValues":_vm.editing,"dynamicOptions":{},"buttons":[]},on:{"formFieldChanged":_vm.formFieldChangedHandler,"validationChanged":function($event){_vm.validationData = $event}}}),_c('ModalBase',{attrs:{"name":"existingScheduleModal","size":"small","clickToClose":false,"showClose":true,"title":"Existing schedule","loading":_vm.loading,"position":"center","bottomButtonPrimary":{
        key: 'save',
        disabled: _vm.disabled,
        label: 'Confirm'
      },"bottomButtonSecondary":{
        key: 'cancel',
        label: 'Cancel'
      }},on:{"primaryButtonClick":_vm.update}},[_c('span',[_vm._v(" A message has already been scheduled. Saving a new one will override the scheduled message. ")])])],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }